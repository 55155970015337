.logoGrid{
  height: auto;
  max-height: 350px;
  overflow-y: scroll;
}
.tooltip {
  position: relative;
  display: flex;
}

.tooltiptext {
  width: 150px;
  background-color: var(--yellow2);
  color: var(--black);
  font-weight: 600;
  text-align: center;
  border-radius: 8px;
  padding: 6px 10px;
}

.tooltiptext > button {
  background: white;
  color: var(--aqua);
  border: 1px solid var(--aqua);
  border-radius: 6px;
  font-weight: 600;
  padding: 10px 0;
  cursor: pointer;
}

.tooltiptext > button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--yellow2) transparent transparent transparent;
}
/* 
.tooltip:hover .tooltiptext {
  visibility: visible;
} */