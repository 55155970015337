.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltiptext {
  visibility: hidden; /* Hidden by default */
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* Invisible by default */
  transition: opacity 0.3s; /* Smooth fade-in effect */
}

.tooltiptextUp {
  bottom: 100%; /* Position the tooltip above the container */
}

.tooltiptextBottom {
  top: 100%; /* Position the tooltip below the container */
}

/* This part adds the arrow */
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}

.tooltip .tooltiptextUp::after {
  top: 100%; /* Arrow at the bottom of the tooltip */
  border-color: black transparent transparent transparent;
}

.tooltip .tooltiptextBottom::after {
  bottom: 100%; /* Arrow at the top of the tooltip */
  border-color: transparent transparent black transparent;
}

/* Show tooltip on hover */
.tooltip:hover .tooltiptext {
  visibility: visible; /* Make the tooltip visible */
  opacity: 1; /* Make the tooltip fully opaque */
}
@media screen and (max-width: 991.98px) {
  .tooltiptext{
    width: 126px;
  }
}