.imageWrapper{
    width: 252px;
    height: 134px;
}
.imageWrapper>img{
    width: 252px;
    height: 134px;
}
@media screen and (max-width: 567.98px) {
    .card{
        flex-direction: column;
    }
    .imageWrapper{
        width: 100%;
        height: 134px;
    }
    .imageWrapper>img{
        width: 100%;
        height: 134px;
    }
}