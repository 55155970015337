/* modal styling */
.modal-container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    background-blend-mode: darken;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-verify {
    margin: 0 auto;
}

.modal-verify>div{
    height: auto;
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
}