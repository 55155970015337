/* This files contains all the neccessary classes for manging the common structure*/
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans&display=swap");
/*
3. Displays
4. Positions
5. Foreground Colors
6. Background-colors
7. Opacity
8. Overflow
9. Buttons
10. Font-families
11. Flex Columns
12. Typography
13. Border Radius & Border
14. Font Weight
15. Badges
16. Forms
17. Drop-Downs
18. Accordians
19. Toggle Button
20. gap
21. Toggle icon
*/

:root {
  --primary: #3d7af5;
  --secondary: #ebf5fc;
  --white: #fff;
  --black: #111;
  --black-2 : #2d2d2d;
  --dark-blue: #2980b9;
  --light-blue: #EEF9FB;
  --grey1: #ecf0f1;
  --grey2: #bdc3c7;
  --grey3: #95a5a6;
  --grey4: #7f8c8d;
  --grey5: #4b5259;
  --grey6: #272c31;
  --grey7: #6a6a6a;
  --grey8: #616161;
  --grey9: #848484;
  --grey10: #3F3F44;
  --grey11: #393939;
  --grey12: #757575;
  --grey13: #F2F8F8;
  --grey14: #585454;
  --grey15: #3F3F3F;
  --grey16: #525252;
  --grey17: #A7A19E;
  --grey18: #F3F3F3;
  --grey19: #938C8C;
  --orange: #ff6b00;
  --green: #13ce3c;
  --light-green: #ECFBFA;
  --card-bg: #f3f3f3;
  --main-bg: #fcfcfc;
  --red: #eb5847;
  --red2: #EC5959;
  --yellow: #deb00e;
  /* blockhole specific*/
  --light-grey: #736b76;
  --light-grey2:#6A6A6A;
  --light-grey3:#F2F6F7;
  --light-pink: #f6dbff;
  --pink: #bf00ff;
  --light-grey1: #f7f4f8;
  --sea-green:#1CBAAB;
  --blue:#196283;
  --light-blue:rgba(218, 242, 250, 1);
  --aqua:#59CAEC;
  --yellow2:#DFE223;
  

  /* card colors */
  --c-yellow: #fff7cf;
  --c-pink: #ffe2ea;
  --c-green: #cef7ef;

  --font-family: "Kumbh Sans", sans-serif;

  /* // temp */
  --step--2: clamp(0.69rem, calc(0.58rem + 0.6vw), 1rem);
  --step--1: clamp(0.83rem, calc(0.67rem + 0.81vw), 1.25rem);
  --step-0: clamp(1rem, calc(0.78rem + 1.1vw), 1.56rem);
  --step-1: clamp(1.2rem, calc(0.91rem + 1.47vw), 1.95rem);
  --step-2: clamp(1.44rem, calc(1.05rem + 1.95vw), 2.44rem);
  --step-3: clamp(1.73rem, calc(1.21rem + 2.58vw), 3.05rem);
  --step-4: clamp(2.07rem, calc(1.39rem + 3.4vw), 3.82rem);
  --step-5: clamp(2.49rem, calc(1.6rem + 4.45vw), 4.77rem);
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  box-sizing: border-box;
  /* font-family: "Kumbh Sans", sans-serif; */
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  background-color: #f5f5f5;
  scroll-behavior: smooth;
}

/* Display Starts */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.z-1000{
  z-index: 1000;
}
.z-12000{
  z-index: 12000;
}
.container{

  width:100%;
  max-width: 1920px;
  margin: 0 auto;

}

.image-contain{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.image-cover{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.obj-pos-top{
  object-position: top;
}
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-inline-flex {
  display: inline-flex;
}
.d-inherit {
  display: inherit;
}
.d-none {
  display: none;
}

.d-flex-wrap {
  flex-wrap: wrap;
}
.d-flex-shrink-0{
   flex-shrink: 0;
}
.d-flex-row {
  flex-direction: row;
}
.d-flex-column {
  flex-direction: column;
}
.d-flex-row-reverse {
  flex-direction: row-reverse;
}
.d-flex-column-reverse {
  flex-direction: column-reverse;
}
.d-align-center {
  align-items: center;
}
.d-align-start {
  align-items: flex-start;
}
.d-align-end {
  align-items: flex-end;
}
.d-align-baseline {
  align-items: baseline;
}
.d-align-content-center {
  align-content: center;
}
.d-align-content-start {
  align-content: flex-start;
}
.d-align-content-end {
  align-content: flex-end;
}
.d-justify-center {
  justify-content: center;
}
.d-justify-start {
  justify-content: flex-start;
}
.d-justify-end {
  justify-content: flex-end;
}
.d-justify-space-between {
  justify-content: space-between;
}
.d-justify-space-around {
  justify-content: space-around;
}
.d-justify-space-evenly {
  justify-content: space-evenly;
}
.d-flex-100 {
  flex: 1 1;
}
.self-end{
  align-self: flex-end;
}
/* Display Ends */

/* Positions Starts */

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.p-fixed {
  position: fixed;
}
.p-sticky {
  position: sticky;
}
.p-static {
  position: static;
}
.p-inherit {
  position: inherit;
}
.p-initial {
  position: initial;
}
.p-unset {
  position: unset;
}
/* Positions Ends */

/* Foreground Colors starts*/
.text-orange {
  color: var(--orange);
}
.text-primary {
  color: var(--pink);
}
.text-secondary {
  color: var(--secondary);
}
.text-grey {
  color: var(--dark-grey);
}
.text-white {
  color: #fff;
}
.text-black {
  color: var(--black);
}
.text-grey-2 {
  color: var(--grey2);
}
.text-grey-3 {
  color: var(--grey3);
}
.text-grey-4 {
  color: var(--grey4);
}
.text-grey-5 {
  color: var(--grey5);
}
.text-grey-6 {
  color: var(--grey6);
}
.text-grey-7 {
  color: var(--grey7);
}
.text-grey-8 {
  color: var(--grey8);
}
.text-grey-9 {
  color: var(--grey9);
}
.text-grey-10 {
  color: var(--grey10);
}
.text-grey-11{
  color: var(--grey11);
}
.text-grey-12{
  color: var(--grey12);
}
.text-grey-14 {
  color: var(--grey14);
}
.text-grey-15{
  color: var(--grey15);
}
.text-grey-16{
  color: var(--grey16);
}
.text-grey-17{
  color: var(--grey17);
}
.text-grey-19{
  color: var(--grey19);
}
.text-green {
  color: var(--green);
}
.text-red {
  color: var(--red);
}
.text-yellow {
  color: var(--yellow);
}
.text-pink{
  color: var(--pink);
}
.text-light-grey{
  color: var(--light-grey);
}
.text-blue{
  color: var(--blue);
}
.text-light-grey2{
  color: var(--light-grey2);
}
.text-aqua{
  color: var(--aqua);
}
.text-sea-green{
  color: var(--sea-green);
}
.text-black-2{
  color:var(--black-2)
}


/* Foreground Colors ends */

/*  Background Colors  starts*/
.bg-none{
  background-color: transparent;
}
.bg-primary {
  background-color: var(--primary);
}
.bg-black{
  background-color: var(--black);
}
.bg-secondary {
  background-color: var(--light-pink);
}
.bg-main {
  background-color: var(--main-bg);
}
.bg-white {
  background-color: var(--white);
}
.bg-grey-1 {
  background-color: var(--grey1);
}
.bg-grey-2 {
  background-color: var(--grey2);
}
.bg-grey-17 {
  background-color: var(--grey17);
}
.bg-grey-13 {
  background-color: var(--grey13);
}
.bg-grey-18 {
  background-color: var(--grey18);
}
.bg-blue {
  background-color: var(--blue);
}
.bg-sea-green {
  background-color: var(--sea-green);
}
.bg-aqua {
  background-color: var(--aqua);
}
.bg-yellow2 {
  background-color: var(--yellow2);
}
.bg-card-yellow {
  background-color: var(--c-yellow);
}
.bg-card-pink {
  background-color: var(--c-pink);
}
.bg-card-green {
  background-color: var(--c-green);
}
.bg-light-green {
  background-color: var(--light-green);
}
.bg-light-blue {
  background-color: var(--light-blue);
}
.bg-light-aqua{
  background-color: #59CAEC4D;
}
/* End Background Colors */

/* Opacity Starts */
.op-1 {
  opacity: 1;
}
.op-01 {
  opacity: 0.1;
}
.op-03 {
  opacity: 0.3;
}
.op-04 {
  opacity: 0.4;
}
.op-05 {
  opacity: 0.5;
}
.op-06 {
  opacity: 0.6;
}
.op-07 {
  opacity: 0.7;
}
.op-08 {
  opacity: 0.8;
}
.op-09 {
  opacity: 0.9;
}

/* Opacity Ends */

/* Overflow Starts */
.o-hidden {
  overflow: hidden;
}
.ox-hidden {
  overflow-x: hidden;
}
.oy-hidden {
  overflow-y: hidden;
}

.o-scroll {
  overflow: scroll;
}
.ox-scroll {
  overflow-x: scroll;
}
.ox-auto {
  overflow-x: auto;
}
.oy-scroll {
  overflow-y: scroll;
}

.o-visible {
  overflow: visible;
}
.ox-visible {
  overflow-x: visible;
}
.oy-visible {
  overflow-y: visible;
}
/* Overflow Ends */

/* Buttons Starts */

.btn {
  padding: 1.1rem 0;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  transition: 0.2s all ease-in-out;
}
.btn:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.btn-modal:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.btn-primary {
  background: var(--pink);
  color: var(--white);
}
.btn-secondary {
  background: var(--light-grey1);
  color: var(--light-grey);
}
.btn-sea-green {
border-radius: 15px;
background: var(--sea-green);
color:white;
padding-inline: 20px;
}
.btn-aqua-round{
  border-radius: 100px;
  background-color: var(--aqua);
  font-weight: 700;
  color: white;
}
.btn-aqua {
border-radius: 15px;
background: var(--aqua);
color:white;
padding-inline: 20px;
}
.btn-white {
background: white;
color: var(--aqua);
border: 1px solid var(--aqua);
border-radius: 15px;
padding-inline: 20px;
}
.btn-blue{
  background: var(--blue);
  color:white;
  padding-inline: 20px;
}
.btn-company-category {
background: white;
color: var(--dark-blue);
border: 1px solid var(--aqua);
border-radius: 15px;
padding-inline: 20px;
}

.btn-company-category:hover {
  background: var(--sea-green);
  border:none;
  color: white
}
.btn-company-category:active {
  background: var(--sea-green);
  border:none;
  color: white
}

.btn-modal{
  display: flex;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
}
.btn-sea-green-thin {
  padding:0.7rem;
background: var(--sea-green);
color:white;
padding-inline: 20px;
}
.btn-aqua-thin {
  padding:0.7rem;
background: var(--aqua);
color:white;
padding-inline: 20px;
}
.btn-white-thin {
  padding:0.7rem;
background: white;
color: var(--aqua);
padding-inline: 20px;
border: 1px solid var(--aqua);
}

.btn-red-thin {
  padding:0.7rem;
background: var(--red2);
color:white;
padding-inline: 20px;
}
.btn-outline-primary {
  background: var(--white);
  color: var(--pink);
  border: 2px solid var(--pink);
}
.btn-outline-sea-green {
  background: var(--white);
  padding: 10px 40px;
  color: var(--sea-green);
  border: 2px solid var(--sea-green);
  border-radius: 30px;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.btn-outline-secondary {
  background: var(--white);
  color: var(--secondary);
  border: 1px solid var(--secondary);
}
.btn-outline-sea-green:hover,
.btn-outline-sea-green:active {
  opacity: 0.85;
  background: var(--sea-green);
  color: var(--white);
}
.btn-primary:hover,
.btn-primary:active {
  opacity: 0.85;
}
.btn-outline-primary:hover,
.btn-outline-primary:active {
  background: var(--pink);
  color: var(--white);
  border: 2px solid var(--pink);
}
.btn-outline-primary:hover svg path{
  fill: var(--white);
}
.btn-secondary:hover,
.btn-secondary:active {
  /* opacity:0.85; */
  background-color: rgb(226, 226, 226);
}
.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  background: var(--secondary);
  color: var(--white);
  border: 2px solid var(--secondary);
}
.btn-default-width {
  min-width: 164px;
}
.btn-default-widthh {
  min-width: 155px;
}
.btn-default{
  padding: 8px 16px;
}
@media screen and (max-width: 768px) {
  .btn-default-widthh {
    padding: 0.8rem 0;
  }
}
.btn-full-width {
  width: 100%;
}

.btn-rounded {
  border-radius: 100px;
}
/* Button Ends */

/* Font-families Starts */
.primary-font {
  font-family: var(--font-family);
}
.secondary-font {
  font-family: var(--font-family);
}

/* Font-families Ends */
/* Classes Related to Typography Starts */
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
span {
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  font-family: inherit;
}
ul,
ol {
  padding: 0 0 0 1.6rem;
  margin: 0 0 1.6rem 0;
}
.list-style-none {
  list-style: none;
}
div {
  font-size: 0.6rem;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor:not-allowed
}
.cursor-default {
  cursor:default;
}
.user-select-none {
  user-select: none;
}
/* Classes Related to Typography Ends */

/* Border Radius Starts */
.rounded {
  border-radius: 12px;
}
.rounded-5 {
  border-radius: 5px;
}
.rounded-8{
  border-radius: 8px;
}
.rounded-10{
  border-radius: 10px;
}
.rounded-12{
  border-radius: 12px;
}
.rounded-15 {
  border-radius: 15px;
}
.rounded-16 {
  border-radius: 16px;
}
.rounded-20 {
  border-radius: 20px;
}
.rounded-30{
  border-radius: 30px;
}
.rounded-32{
  border-radius: 32px;
}
.rounded-40 {
  border-radius: 40px;
}
.rounded-50 {
  border-radius: 50%;
}
.rounded-top-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.rounded-top-20 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.rounded-bottom-20 {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.rounded-bottom-20 {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.border {
  border: 1px solid #e6e6e6;
}
.border-thick {
  border: 5px solid #e6e6e6;
}
.border-thick-white {
  border: 5px solid #ffffff;
}
.border-white {
  border: 1px solid rgb(195, 195, 195);
}

.border-light-grey {
  border: 1px solid #f6f6f6;
}

.border-pink {
  border: 1px solid var(--pink);
}
.border-primary {
  border: 1px solid var(--primary);
}
.border-danger {
  border: 1px solid var(--red);
}
.border-success {
  border: 1px solid var(--green);
}
.border-sea-green {
  border: 1px solid var(--sea-green);
}
.border-grey{
  border: 1px solid rgba(45, 45, 45, 0.10);
}
.border-grey-2{
  border: 1px solid var(--grey2);
}
.border-grey-17{
  border: 1px solid var(--grey17);
}

.border-right-grey{
  border-right: 1px solid rgba(45, 45, 45, 0.10);
}

.border-bottom {
  border-bottom: 1px solid #e6e6e6;
}
.border-bottom-dark {
  border-bottom: 1px solid #BCBABA;
}
.border-right {
  border-right: 1px solid #e6e6e6;
}
.border-top {
  border-top: 1px solid #e6e6e6;
}
.border-left {
  border-left: 1px solid #e6e6e6;
}
.border-none {
  border: none;
}

/* Box Shadows */
.box-shadow{
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05)
}
.box-shadow1{
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15)
}
.box-shadow2{
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
}


/* Border Radius Ends */

/*gap start*/
.gap-1 {
  gap: 0.5rem;
}
.gap-2 {
  gap: 1rem;
}
.gap-3 {
  gap: 1.5rem;
}
.gap-4 {
  gap: 2rem;
}
.gap-5 {
  gap: 2.5rem;
}
.gap-6 {
  gap: 3rem;
}
.gap-7 {
  gap: 3.5rem;
}
.gap-8 {
  gap: 4rem;
}
.gap-9 {
  gap: 4.5rem;
}
.gap-10 {
  gap: 5rem;
}
.gap-15{
  gap: 7.5rem
}
/*gap end*/

/* positions start */
.left-0 {
  left:0
}
.top-0 {
  top:0
}
.left-50 {
  left:50%
}
.top-50 {
  top:50%
}
.right-0 {
  right:0
}
.bottom-0 {
  bottom:0
}
/* positions end */

/* Height and width start */
.h-full{
  height:100%
}
.h-267px{
  height: 267px;
}
.w-full{
  width:100%
}
.max-w-full{
  max-width:100%
}
/* Height and width end */

/* object-fit start */
.of-cover{
  object-fit: cover;
}
.of-contain{
  object-fit: contain;
}
/* object-fit end */

/*project specific styles */

.main-class {
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: clamp(1.25rem, 0.3893rem + 1.9672vw, 2.75rem)
    clamp(1.125rem, 0.3361rem + 1.8033vw, 2.5rem)
    clamp(0.625rem, -0.0205rem + 1.4754vw, 1.75rem)
    clamp(1.25rem, 0.3893rem + 1.9672vw, 2.75rem);
}
.word-break {
  word-break: break-word;
}
.text-overflow-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pending {
  background: rgba(113, 87, 244, 0.1);
  color: #7157f4;
}
.spam {
  background: rgba(255, 66, 47, 0.1);
  color: #ff422f;
}
.verified {
  background: rgba(28, 196, 75, 0.1);
  color: #1cc44b;
}
.unverified {
  background: rgba(255, 107, 0, 0.1);
  color: #ff6b00;
}

/* grid columns */
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
}
.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
  /* column-gap:50px; */
}
.grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
  /* column-gap:50px; */
}
.grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
}
.grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
}
.grid-col-6 {
  grid-template-columns: repeat(6, 1fr);
}
.grid-col-12 {
  grid-template-columns: repeat(12, 1fr);
}
.border-bold-primary {
  border: 4px solid #3d7af5;
}
.circular-button {
  /* background-color:var(--light-grey1); */
  background-color: rgb(234, 234, 234);
  /* padding: 14px 7px 14px 7px; */
  border-radius: 50%;
}
.circular-button:hover {
  background-color: rgb(226, 226, 226);
}
.input-field {
  font-weight: 500;
  font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
  margin: 0;
  line-height: 3rem;
  /* line-height: clamp(1.25rem, 1.225rem + 0.125vw, 1.375rem); */
  background: #FFF;
  border-radius: 16px;
  border: 1px solid #D5D5D5;
}
.form-input-field-div {
  font-weight: 500;
  font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
  margin: 0;
  line-height: 3rem;
  /* line-height: clamp(1.25rem, 1.225rem + 0.125vw, 1.375rem); */
  background: #FFF;
  border-radius: 30px;
  border: 1px solid #D8D8D8;
}

.form-input-field{

  font-weight: 500;
  font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
  margin: 0;
  line-height: 3rem;
  /* line-height: clamp(1.25rem, 1.225rem + 0.125vw, 1.375rem); */
  background: #FFF;
  border-radius: 16px;

}
.form-input-field:focus{
  outline: none;
}

.input-field:focus{
  border: 2px solid var(--sea-green);
}
.input-field:focus {
  outline: none;
}
::placeholder {
  color: #D5D5D5;
  opacity: 1; /* Firefox */
  font-size: clamp(1rem, 0.9500rem + 0.2500vw, 1.25rem);
}

.input-field-solid {
  font-weight: 500;
  font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
  margin: 0;
  line-height: 3.5rem; 
  /* line-height: clamp(1.25rem, 1.225rem + 0.125vw, 1.375rem); */
  background: #EDF2F1;
  border-radius: 16px;
  border: 0px;
}
.input-field-solid:focus{
  background: #dde3e2;
}
.input-field-white {
  font-weight: 500;
  padding: 10px;
  /* font size = 12px */
  font-size: clamp(0.8rem, 0.7750rem + 0.0000vw, 0.8rem); 
  margin: 0;
  line-height: 1rem;
  /* line-height: clamp(1.25rem, 1.225rem + 0.125vw, 1.375rem); */
  background: none;
  border-radius: 40px;
  border: none;
}
.settings-input-field:focus{
   outline: none;
}

.search-bar{
  border:none;
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.search-bar:focus{
  outline:none
}
.search-bar::placeholder{
  font-size:16px;
  color: rgba(45, 45, 45, 0.30);
  font-weight: 400;
}

.input-field-white:focus{
  outline:none
}
.input-field-solid:focus{
  outline:none
}

.input-field-solid::placeholder{

  color:#949494;
  opacity: 1; /* Firefox */
  font-size: clamp(1rem, 0.9500rem + 0.2500vw, 1.25rem);
  font-weight: 400;
}
#type-input-box::placeholder{
  color:#949494;
  opacity: 1; /* Firefox */
  font-size: clamp(1rem, 0.9500rem + 0.2500vw, 1.25rem);
  font-weight: 400;
}
.input-field-white::placeholder{
  color:#B6B6B6;
  opacity: 1; /* Firefox */
  font-size: clamp(0.0625rem, -0.1250rem + 0.9375vw, 1rem);
  font-weight: 400;
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color:var(--light-grey2);
  font-size: clamp(1rem, 0.9500rem + 0.2500vw, 1.25rem);
}

.text-area{
  resize: none;
}
.text-area::placeholder{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text {
  font-size: 16px;
}
.profile-picture {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--primary);
  border-radius: 50%;
}

.hover-effect:hover{

  border: 2px solid black;
  cursor:pointer;

}
.single-line-div {
  display: inline-block;
  white-space: nowrap;
}
.outline-none{
  outline: none;
}
.object-cover{
  object-fit: cover;
}
.object-contain{
  object-fit: contain;
}
.btn-inactive{
  background-color: var(--grey2);
  padding: 10px 40px;
    color: var(--white);
    border-radius: 30px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.btn-disable-score{
  position: relative;
  cursor: not-allowed;
  background-color: var(--grey2);
}
.btn-disable-score:hover::before{
  position: absolute;
  width: 192px;
  padding: 4px 2px;
  content: "Hang tight, as we grow we’ll bring this feature soon!";
  top: -32px;
  left: 0px;
  background-color: var(--black);
  font-size: 12px;
  color: var(--white);
  border-radius: 4px;
  z-index: 2;
}
.word-break{
  word-wrap: break-word;
}
@media screen and (max-width:449.98px){
  .btn-disable-score:hover::before{
    left: -40px;
  }
}
@media (max-width : 650px) {

  .btn{
    padding: 10px;
  }
}
.swiper-container .swiper-pagination-bullet {
  background: #DAF2FA;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  opacity: 1;
}
.swiper-container .swiper-pagination-bullet-active {
  background: var(--aqua);
}
.desc-wrap{
  display: -webkit-box;
  max-width: calc(100% / 12*12);
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-text-wrap{
  white-space:normal;
 overflow-wrap:break-word;
}
.dropdown-items:hover{
  background-color: var(--c-green);
  border-radius: 8px;
}
input[type=date] {
  font-weight: 500;
  font-size: clamp(0.75rem, 0.7250rem + 0.1250vw, 0.875rem);
  font-family: "Kumbh Sans",
    sans-serif;
}
.text-ellipsis-1 {
  display: -webkit-box;
  max-width: calc(100% / 12*12);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ellipsis-2 {
  display: -webkit-box;
  max-width: calc(100% / 12*12);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ellipsis-3 {
  display: -webkit-box;
  max-width: calc(100% / 12*12);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grow-font-size>div {
  font-size: 16px !important;
}
.status-btn{
  display:flex ;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding:4px 12px 4px 10px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  border: none;
}
.status-btn span{
  width: 8px;
  height: 8px;
  border-radius: 100px;
}
.status-btn.status-btn-success{
  background-color: #D2FFE4;
  color: #027A48;
}
.status-btn.status-btn-success>span{
  background-color: #12B76A;
}
.status-btn.status-btn-error{
  background-color: #FF8686;
  color: #990000;
}
.status-btn.status-btn-error>span{
  background-color: #990000;
}
.status-btn.status-btn-warn{
  background-color: #FFF1A5;
  color: #8C7500;
}
.status-btn.status-btn-warn>span{
  background-color: #8C7500;
}
.claim-portal-wrapper{
  height:auto;
  min-height:100vh;
}
.word-break{
  word-break: break-word;
}
.tooltiptext {
  width: 150px;
  font-weight: 600;
  text-align: center;
  border-radius: 8px;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--black) transparent transparent transparent;
}