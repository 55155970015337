.wrapper{
    width: 137px;
    height: 144px;
}
.overlay{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000B2;
    border-radius: 16px;
}
.overlay>span{
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 100px;
    cursor: pointer;
}