@media screen and (max-width: 767.98px) {
  .wrapper {
    width: 100%;
    display: flex;
    margin: 0px;
  }
  .nav-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    width: 100vw;
    margin-top: 40px;
    padding: 20px;
    z-index: 2;
    animation: mymove 1s;
    box-shadow: 0px 4px 40px 3px rgba(234, 234, 234, 0.4);
    gap: 20px;
  }
  @keyframes mymove {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .icon > div {
    transition: all 1s;
  }

  .icon.menu-icon {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
    margin-right: 8px;
  }

  .icon.menu-icon > div {
    align-self: end;
    height: 2px;
    width: 32px;
  }

  .icon.close-icon {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s;
    top: 10px;
    margin-right: 4px;
  }

  .icon.close-icon > div:nth-child(2) {
    display: none;
  }

  .icon.close-icon > div:nth-child(1),
  .icon.close-icon > div:nth-child(3) {
    height: 2px;
    width: 30px;
    position: relative;
  }

  .icon.close-icon > div:nth-child(1) {
    rotate: 45deg;
  }

  .icon.close-icon > div:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    rotate: -45deg;
  }
}
