@media screen and (max-width: 1199.98px) {
  .dashboardWrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .dashboardWrapper > div:nth-child(1) {
    padding-right: 0;
  }
}
@media screen and (max-width: 767.98px) {
  .dashboardWrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 0px;
    width: 100%;
  }
  .formFilters {
    display: flex;
    flex-wrap: wrap;
  }
  .formFilters > div:nth-child(1) {
    width: 100%;
  }
  .formFilters > div:nth-child(2) {
    width: 100%;
  }
  .companiesWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
