.video-container{
    position: relative;
    height: fit-content;
    overflow: hidden;
}
@media screen and (max-width: 1199.98px) {
    .carouselWrapper{
        flex-direction: column-reverse;
        gap: 32px;
    }
}
@media screen and (max-width: 567.98px) {
    .btnWrapper{
        gap: 8px;
    }
    .btnWrapper>button{
        font-size: 14px;
    }
}