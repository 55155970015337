.wrapper{
    margin-inline: 6%;
    margin-bottom: 5%;
}
.cardGrid{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 1199.98px) {
    .wrapper{
        margin-inline: 0px;
        padding: 8px;
    }
    .cardGrid{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 767.98px) {
    .searchForm{
        display:grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .cardGrid{
        display:grid;
        grid-template-columns: repeat(1, 1fr);
    }
}