.popUp{
    width: 400px;
    margin-top: 70px;
    z-index: 2;
    border-radius: 18px;
    padding: 0;
    margin-bottom: 0;
    box-shadow: 0px 6px 12px 0px #0000002E;
}
.popUp>li:nth-child(odd){
    background-color: #F2F2F2;
}
.popUp>a{
   background-color:#B5EEFF;
   color: #00B4FF;
}
@media screen and (max-width: 767.98px) {
    .modalWrapper{
        justify-content: flex-end;
    }
}
@media screen and (max-width: 567.98px) {
    .popUp{
        width: 300px;
        margin-right: -32px;
    }
}