@media screen and (max-width: 991.98px) {
    .peopleCardGrid{
        grid-template-columns: repeat(2,1fr);
    }
    .investorsCardGrid{
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width: 767.98px) {
    .peopleCardGrid{
        grid-template-columns: repeat(1,1fr);
    }
    .investorsCardGrid{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width: 567.98px) {
    .investorsCardGrid{
        grid-template-columns: repeat(1,1fr);
    }
}