.wrapper{
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    overflow-x: scroll;
    max-width: 300px;
}
.wrapper::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}
.wrapper>div{
    margin-right: 8px;
}