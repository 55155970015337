.sidebar,
.claim-form {
  background: linear-gradient(rgb(245, 245, 245) 0%, rgb(238, 249, 251) 94.91%);
}
.tabList > li {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  border-radius: 8px;
}
.tabList .active {
  background-color: var(--sea-green);
  color: white;
}
.tabList .inactive {
  background-color: transparent;
  color: #555555;
  cursor: pointer;
}
.claim-form {
  padding: 30px;
}
input {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  outline: none;
}
input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #b3b3b3;
}
table,
thead,
tbody,
tr,
th,
td {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}
.tableWrapper {
  height: auto;
  border-radius: 8px;
  border : 1px solid #1cbaab4d;
  /* border-left: 1px solid #1cbaab4d;
  border-top: 1px solid #1cbaab4d;
  border-right: 1px solid #1cbaab4d; */
  overflow-x: hidden;
  overflow-x: scroll;
}
.tableSearchHeader {
  position: sticky;
  top: 0;
  left: 0;
  padding: 10px 12px 10px 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #59caec4d;
}
.table {
  min-width: 2200px;
  width: 100%;
  background-color: var(--white);
}
.tableHeader {
  height: 48px;
  top: 0;
  position: sticky;
  z-index: 1;
  color: var(--sea-green);
  border-bottom: 0.3px solid #26847e;
}
.tableHeader * {
  font-size: 16px;
  font-weight: 600;
  line-height: 23.2px;
  letter-spacing: 0.01em;
}
.tableBody {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #141414;
}
.tableBody > tr {
  height: 48px;
  border-bottom: 0.3px solid #26847e;
}
.tableBody > tr:last-child {
  border-bottom: none;
}
.table-pagination{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #1cbaab4d;
  position: sticky;
  left: 0;
}
.page-blocks{
  border: 1px solid #DCDBE1;
}
.page-blocks>*{
  width: 40px;
  height: 40px;
  border-right: 1px solid #DCDBE1;
  cursor: pointer;
}
.page-blocks>span:last-child{
  border-right: none;
}
@media screen and (max-width: 1199.98px) {
  .container{
    flex-direction: column;
  }
  .sidebar>ul{
    flex-direction: row;
  }
}
@media screen and (max-width: 991.98px) {
  .claim-form{
    flex-direction: column;
    gap: 16px;
  }
}
@media screen and (max-width: 567.98px) {
  .claim-form{
    flex-direction: column;
    gap: 16px;
  }
  .formInput{
    flex-direction: column;
  }
}