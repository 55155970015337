.banner {
    position: absolute;
    top: 140px;
    left: -40px;
    width: 250px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0;
    transform: rotate(-45deg);
    transform-origin: left top;
    /* z-index: 1000; */
}
.banner .icon {
    width: 26px;
    height: 26px;
    margin-right: 5px;
}