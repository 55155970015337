/* These classes have been used for structure your code in more convinent approach.
Do use following 12 column Grid structure in your projects.

Basic Terms:
- 100% of screen = 12 columns
- 1 columns = (100% / 12)
- row: for using columns you need to add row in parent div
- col: stands for column and it is universal for all
- offset: for adding the margin from left

Breakpoints
- col: universal
- col-xs: min-width 360px
- col-sm: min-width 568px
- col-md: min-width 768px
- col-lg: min-width 991px
- col-xl: min-width 1200px
- col-xxl: min-width 1400px 
- col-xxxl: min-width 2100px

*/
.row{
    display: flex;
    flex-wrap: wrap;
}

@media screen and (min-width:320px){
    .col-1{
        width:calc(100% / 12 * 1);
    }
    .col-2-5{
        width:calc(100% / 12 * 2.5);
    }
    .col-2{
        width:calc(100% / 12 * 2);
    }
    .col-3{
        width:calc(100% / 12 * 3);
    }
    .col-4{
        width:calc(100% / 12 * 4);
    }
    .col-5{
        width:calc(100% / 12 * 5);
    }
    .col-6{
        width:calc(100% / 12 * 6);
    }
    .col-7{
        width:calc(100% / 12 * 7);
    }
    .col-8{
        width:calc(100% / 12 * 8);
    } 
    .col-9{
        width:calc(100% / 12 *9);
    }
    .col-10{
        width:calc(100% / 12 * 10);
    }
    .col-11{
        width:calc(100% / 12 * 11);
    }
    .col-12{
        width:calc(100% / 12 * 12);
    }
    .offset-1{
        margin-left: calc(100% / 12 * 1);
    }
    .offset-2{
        margin-left: calc(100% / 12 * 2);
    }
    .offset-3{
        margin-left: calc(100% / 12 * 3);
    }
    .offset-35{
        margin-left: calc(100% / 12 * 3.5);
    }
    .offset-4{
        margin-left: calc(100% / 12 * 4);
    }
    .offset-5{
        margin-left: calc(100% / 12 * 5);
    }
    .offset-6{
        margin-left: calc(100% / 12 * 6);
    }
    .offset-7{
        margin-left: calc(100% / 12 * 7);
    }
    .offset-8{
        margin-left: calc(100% / 12 * 8);
    }
    .offset-9{
    margin-left: calc(100% / 12 * 9);
    }
    .offset-10{
        margin-left: calc(100% / 12 * 10);
    }
    .offset-11{
        margin-left: calc(100% / 12 * 11);
    }
    .col-grid-4{
        grid-template-columns: repeat(4, 1fr);
    }
}
@media screen and (min-width:359.98px) {
    .col-xs-1{
        width:calc(100% / 12 * 1);
    }
    .col-xs-2{
        width:calc(100% / 12 * 2);
    }
    .col-xs-3{
        width:calc(100% / 12 * 3);
    }
    .col-xs-4{
        width:calc(100% / 12 * 4);
    }
    .col-xs-5{
        width:calc(100% / 12 * 5);
    }
    .col-xs-6{
        width:calc(100% / 12 * 6);
    }
    .col-xs-7{
        width:calc(100% / 12 * 7);
    }
    .col-xs-8{
        width:calc(100% / 12 * 8);
    }
    .col-xs-9{
        width:calc(100% / 12 *9);
    }
    .col-xs-10{
        width:calc(100% / 12 * 10);
    }
    .col-xs-11{
        width:calc(100% / 12 * 11);
    }
    .col-xs-12{
        width:calc(100% / 12 * 12);
    }
    .offset-xs-1{
        margin-left: calc(100% / 12 * 1);
    }
    .offset-xs-2{
        margin-left: calc(100% / 12 * 2);
    }
    .offset-xs-3{
        margin-left: calc(100% / 12 * 3);
    }
    .offset-xs-4{
        margin-left: calc(100% / 12 * 4);
    }
    .offset-xs-5{
        margin-left: calc(100% / 12 * 5);
    }
    .offset-xs-6{
        margin-left: calc(100% / 12 * 6);
    }
    .offset-xs-7{
        margin-left: calc(100% / 12 * 7);
    }
    .offset-xs-8{
        margin-left: calc(100% / 12 * 8);
    }
    .offset-xs-9{
    margin-left: calc(100% / 12 * 9);
    }
    .offset-xs-10{
        margin-left: calc(100% / 12 * 10);
    }
    .offset-xs-11{
        margin-left: calc(100% / 12 * 11);
    }
}
@media screen and (min-width:567.98px) {
    .col-sm-1{
        width:calc(100% / 12 * 1);
    }
    .col-sm-2{
        width:calc(100% / 12 * 2);
    }
    .col-sm-3{
        width:calc(100% / 12 * 3);
    }
    .col-sm-4{
        width:calc(100% / 12 * 4);
    }
    .col-sm-5{
        width:calc(100% / 12 * 5);
    }
    .col-sm-6{
        width:calc(100% / 12 * 6);
    }
    .col-sm-7{
        width:calc(100% / 12 * 7);
    }
    .col-sm-8{
        width:calc(100% / 12 * 8);
    }
    .col-sm-9{
        width:calc(100% / 12 *9);
    }
    .col-sm-10{
        width:calc(100% / 12 * 10);
    }
    .col-sm-11{
        width:calc(100% / 12 * 11);
    }
    .col-sm-12{
        width:calc(100% / 12 * 12);
    }
    .offset-sm-1{
        margin-left: calc(100% / 12 * 1);
    }
    .offset-sm-2{
        margin-left: calc(100% / 12 * 2);
    }
    .offset-sm-3{
        margin-left: calc(100% / 12 * 3);
    }
    .offset-sm-4{
        margin-left: calc(100% / 12 * 4);
    }
    .offset-sm-5{
        margin-left: calc(100% / 12 * 5);
    }
    .offset-sm-6{
        margin-left: calc(100% / 12 * 6);
    }
    .offset-sm-7{
        margin-left: calc(100% / 12 * 7);
    }
    .offset-sm-8{
        margin-left: calc(100% / 12 * 8);
    }
    .offset-sm-9{
    margin-left: calc(100% / 12 * 9);
    }
    .offset-sm-10{
        margin-left: calc(100% / 12 * 10);
    }
    .offset-sm-11{
        margin-left: calc(100% / 12 * 11);
    }
}
@media screen  and (min-width:767.98px){
    .col-md-1{
        width:calc(100% / 12 * 1);
    }
    .col-md-2{
        width:calc(100% / 12 * 2);
    }
    .col-md-3{
        width:calc(100% / 12 * 3);
    }
    .col-md-4{
        width:calc(100% / 12 * 4);
    }
    .col-md-5{
        width:calc(100% / 12 * 5);
    }
    .col-md-6{
        width:calc(100% / 12 * 6);
    }
    .col-md-7{
        width:calc(100% / 12 * 7);
    }
    .col-md-8{
        width:calc(100% / 12 * 8);
    }
    .col-md-9{
        width:calc(100% / 12 *9);
    }
    .col-md-10{
        width:calc(100% / 12 * 10);
    }
    .col-md-11{
        width:calc(100% / 12 * 11);
    }
    .col-md-12{
        width:calc(100% / 12 * 12);
    }
    .offset-md-1{
        margin-left: calc(100% / 12 * 1);
    }
    .offset-md-2{
        margin-left: calc(100% / 12 * 2);
    }
    .offset-md-3{
        margin-left: calc(100% / 12 * 3);
    }
    .offset-md-4{
        margin-left: calc(100% / 12 * 4);
    }
    .offset-md-5{
        margin-left: calc(100% / 12 * 5);
    }
    .offset-md-6{
        margin-left: calc(100% / 12 * 6);
    }
    .offset-md-7{
        margin-left: calc(100% / 12 * 7);
    }
    .offset-md-8{
        margin-left: calc(100% / 12 * 8);
    }
    .offset-md-9{
    margin-left: calc(100% / 12 * 9);
    }
    .offset-md-10{
        margin-left: calc(100% / 12 * 10);
    }
    .offset-md-11{
        margin-left: calc(100% / 12 * 11);
    }
}
@media screen and (min-width:991.98px){
    .col-lg-1{
        width:calc(100% / 12 * 1);
    }
    .col-lg-2{
        width:calc(100% / 12 * 2);
    }
    .col-lg-3{
        width:calc(100% / 12 * 3);
    }
    .col-lg-4{
        width:calc(100% / 12 * 4);
    }
    .col-lg-5{
        width:calc(100% / 12 * 5);
    }
    .col-lg-6{
        width:calc(100% / 12 * 6);
    }
    .col-lg-7{
        width:calc(100% / 12 * 7);
    }
    .col-lg-8{
        width:calc(100% / 12 * 8);
    }
    .col-lg-9{
        width:calc(100% / 12 *9);
    }
    .col-lg-10{
        width:calc(100% / 12 * 10);
    }
    .col-lg-11{
        width:calc(100% / 12 * 11);
    }
    .col-lg-12{
        width:calc(100% / 12 * 12);
    }
    .offset-lg-1{
        margin-left: calc(100% / 12 * 1);
    }
    .offset-lg-2{
        margin-left: calc(100% / 12 * 2);
    }
    .offset-lg-3{
        margin-left: calc(100% / 12 * 3);
    }
    .offset-lg-4{
        margin-left: calc(100% / 12 * 4);
    }
    .offset-lg-5{
        margin-left: calc(100% / 12 * 5);
    }
    .offset-lg-6{
        margin-left: calc(100% / 12 * 6);
    }
    .offset-lg-7{
        margin-left: calc(100% / 12 * 7);
    }
    .offset-lg-8{
        margin-left: calc(100% / 12 * 8);
    }
    .offset-9{
    margin-left: calc(100% / 12 * 9);
    }
    .offset-lg-10{
        margin-left: calc(100% / 12 * 10);
    }
    .offset-lg-11{
        margin-left: calc(100% / 12 * 11);
    }
}
@media screen and (min-width:1199.98px) {
    .col-xl-1{
        width:calc(100% / 12 * 1);
    }
    .col-xl-2{
        width:calc(100% / 12 * 2);
    }
    .col-xl-3{
        width:calc(100% / 12 * 3);
    }
    .col-xl-4{
        width:calc(100% / 12 * 4);
    }
    .col-xl-5{
        width:calc(100% / 12 * 5);
    }
    .col-xl-6{
        width:calc(100% / 12 * 6);
    }
    .col-xl-7{
        width:calc(100% / 12 * 7);
    }
    .col-xl-8{
        width:calc(100% / 12 * 8);
    }
    .col-xl-9{
        width:calc(100% / 12 *9);
    }
    .col-xl-10{
        width:calc(100% / 12 * 10);
    }
    .col-xl-11{
        width:calc(100% / 12 * 11);
    }
    .col-xl-12{
        width:calc(100% / 12 * 12);
    }
    .offset-xl-1{
        margin-left: calc(100% / 12 * 1);
    }
    .offset-xl-2{
        margin-left: calc(100% / 12 * 2);
    }
    .offset-xl-3{
        margin-left: calc(100% / 12 * 3);
    }
    .offset-xl-4{
        margin-left: calc(100% / 12 * 4);
    }
    .offset-xl-5{
        margin-left: calc(100% / 12 * 5);
    }
    .offset-xl-6{
        margin-left: calc(100% / 12 * 6);
    }
    .offset-xl-7{
        margin-left: calc(100% / 12 * 7);
    }
    .offset-xl-8{
        margin-left: calc(100% / 12 * 8);
    }
    .offset-xl-9{
    margin-left: calc(100% / 12 * 9);
    }
    .offset-xl-10{
        margin-left: calc(100% / 12 * 10);
    }
    .offset-xl-11{
        margin-left: calc(100% / 12 * 11);
    }
}
@media screen and (min-width:1399.98px) {
    .col-xxl-1{
        width:calc(100% / 12 * 1);
    }
    .col-xxl-2{
        width:calc(100% / 12 * 2);
    }
    .col-xxl-3{
        width:calc(100% / 12 * 3);
    }
    .col-xxl-4{
        width:calc(100% / 12 * 4);
    }
    .col-xxl-5{
        width:calc(100% / 12 * 5);
    }
    .col-xxl-6{
        width:calc(100% / 12 * 6);
    }
    .col-xxl-7{
        width:calc(100% / 12 * 7);
    }
    .col-xxl-8{
        width:calc(100% / 12 * 8);
    }
    .col-xxl-9{
        width:calc(100% / 12 *9);
    }
    .col-xxl-10{
        width:calc(100% / 12 * 10);
    }
    .col-xxl-11{
        width:calc(100% / 12 * 11);
    }
    .col-xxl-12{
        width:calc(100% / 12 * 12);
    }
    .offset-xxl-1{
        margin-left: calc(100% / 12 * 1);
    }
    .offset-xxl-2{
        margin-left: calc(100% / 12 * 2);
    }
    .offset-xxl-3{
        margin-left: calc(100% / 12 * 3);
    }
    .offset-xxl-4{
        margin-left: calc(100% / 12 * 4);
    }
    .offset-xxl-5{
        margin-left: calc(100% / 12 * 5);
    }
    .offset-xxl-6{
        margin-left: calc(100% / 12 * 6);
    }
    .offset-xxl-7{
        margin-left: calc(100% / 12 * 7);
    }
    .offset-xxl-8{
        margin-left: calc(100% / 12 * 8);
    }
    .offset-xxl-9{
    margin-left: calc(100% / 12 * 9);
    }
    .offset-xxl-10{
        margin-left: calc(100% / 12 * 10);
    }
    .offset-xxl-11{
        margin-left: calc(100% / 12 * 11);
    }
}
@media screen and (min-width:2099.98px) {
    .col-xxxl-1{
        width:calc(100% / 12 * 1);
    }
    .col-xxxl-2{
        width:calc(100% / 12 * 2);
    }
    .col-xxxl-3{
        width:calc(100% / 12 * 3);
    }
    .col-xxxl-4{
        width:calc(100% / 12 * 4);
    }
    .col-xxxl-5{
        width:calc(100% / 12 * 5);
    }
    .col-xxxl-6{
        width:calc(100% / 12 * 6);
    }
    .col-xxxl-7{
        width:calc(100% / 12 * 7);
    }
    .col-xxxl-8{
        width:calc(100% / 12 * 8);
    }
    .col-xxxl-9{
        width:calc(100% / 12 *9);
    }
    .col-xxxl-10{
        width:calc(100% / 12 * 10);
    }
    .col-xxxl-11{
        width:calc(100% / 12 * 11);
    }
    .col-xxxl-12{
        width:calc(100% / 12 * 12);
    }
    .offset-xxxl-1{
        margin-left: calc(100% / 12 * 1);
    }
    .offset-xxxl-2{
        margin-left: calc(100% / 12 * 2);
    }
    .offset-xxxl-3{
        margin-left: calc(100% / 12 * 3);
    }
    .offset-xxxl-4{
        margin-left: calc(100% / 12 * 4);
    }
    .offset-xxxl-5{
        margin-left: calc(100% / 12 * 5);
    }
    .offset-xxxl-6{
        margin-left: calc(100% / 12 * 6);
    }
    .offset-xxxl-7{
        margin-left: calc(100% / 12 * 7);
    }
    .offset-xxxl-8{
        margin-left: calc(100% / 12 * 8);
    }
    .offset-xxxl-9{
    margin-left: calc(100% / 12 * 9);
    }
    .offset-xxxl-10{
        margin-left: calc(100% / 12 * 10);
    }
    .offset-xxxl-11{
        margin-left: calc(100% / 12 * 11);
    }
}