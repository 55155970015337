@media screen and (max-width: 1199.98px) {
    .wrapper{
        gap: 48px;
    }
    .contentWrapper{
        flex-direction: column;
        gap: 32px;
    }
    .sidePanel>div{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
    .locationGrid{
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
    }
}
@media screen and (max-width: 991.98px) {
    .locationGrid{
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
}
@media screen and (max-width: 567.98px) {
    .sidePanel>div{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .locationGrid{
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
    }
}