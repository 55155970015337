@media screen and (max-width: 767.98px) {
    .card{
        flex-direction: column;
        gap: 8px;
    }
    .btnWrapper{
        flex-direction: row;
    }
}
@media screen and (max-width: 567.98px) {
    .card{
        flex-direction: column;
        gap: 8px;
    }
    .btnWrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
    }
    .btnWrapper button:nth-child(1),
    .btnWrapper button:nth-child(2) {
        grid-column: span 1; /* Each takes one column in the first row */
    }

    .btnWrapper button:nth-child(3) {
        grid-column: span 2; /* This spans across both columns in the second row */
    }
}