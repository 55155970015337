.filterWrapper{
    width: 58px;
    height: 58px;
    border-radius: 20px;
    background-color: #EDF2F1;
}
.filterWrapper>span{
    width: 16px;
    height: 16px;
    top: -2px;
    right: -2px;
}
.popUp{
    width: 400px;
    margin-top: 60px;
    z-index: 2;
}
.btnWrapper>*{
    width: 140px;
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    cursor: pointer;
}
.btnWrapper>*:nth-child(1){
    border: 1px solid var(--sea-green);
    color: var(--sea-green);
}
.btnWrapper>*:nth-child(2) {
    background-color: var(--sea-green);
    color: #FFFFFF;
    border: none
}
@media screen and (max-width: 767.98px) {
    .modalWrapper{
        justify-content: flex-start;
    }
}
@media screen and (max-width: 567.98px) {
    .popUp{
        width: 300px;
    }
}