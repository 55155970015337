.header{
    z-index: 999;
}
.popUp{
    top: 120px;
    right: 20px;
}
@media screen and (max-width: 1199.98px) {
    .popUp{
        top: 100px;
        right: 0px;
    }
}
@media screen and (max-width: 991.98px) {
    .popUp{
        top: 90px;
        right: 0px;
    }
}
@media screen and (max-width: 991.98px) {
    .navBtns{
        gap: 32px;
    }
    .dropdownBtn>div:nth-child(2),.dropdownBtn>div:nth-child(3){
        display: none;
    }
    .popUp{
        top: 80px;
        right: 0px;
    }
}
@media screen and (max-width: 567.98px) {
    .navBtns{
       width: 100%;
       gap: 20px;
    }
    .popUp{
        top: 148px;
        right: -16px;
    }
}