@media screen and (max-width: 1399.98px) {
    .container{
        flex-direction: column
    }
    .sidePanelUpper{
        flex-direction: row;
    }
    .actionButtons{
        flex-direction: row;
    }
}