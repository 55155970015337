/* Typorgraphy file for Scaling. Do create all classes for each font and line height use in your project. */

/* 32px */
h1,.h1{
    font-size: clamp(1.625rem, 1.5500rem + 0.3750vw, 2rem);
    margin:0;
}
/* 26px */
h2,.h2{
    font-size: clamp(1.375rem, 1.3250rem + 0.2500vw, 1.625rem);
    margin:0;
}
/* 20px */
h3,.h3{
    font-size: clamp(1.125rem, 1.1000rem + 0.1250vw, 1.25rem);
    margin:0;
}
/* 18px */
h4,.h4{
    font-size: clamp(1rem, 0.9750rem + 0.1250vw, 1.125rem);
    margin:0;
}
/* 16px */
h5,.h5{
    font-size: clamp(0.875rem, 0.8500rem + 0.1250vw, 1rem);
    margin:0;
}
/* 14px */
h6,.h6{
    font-size: clamp(0.75rem, 0.7250rem + 0.1250vw, 0.875rem);
    margin:0;
}
p,li,span{
    font-size: clamp(0.875rem, 0.8500rem + 0.1250vw, 1rem);	
}
.font-10{
    font-size: clamp(0.5rem, 0.4750rem + 0.1250vw, 0.625rem);
}
.font-12{
    font-size: clamp(0.8rem, 0.7750rem + 0.0000vw, 0.8rem);
}
.font-14 {
    font-size: clamp(0.875rem, 0.8000rem + 0.1875vw, 0.875rem);
  }
.font-16{
    font-size: clamp(0.875rem, 0.8500rem + 0.1250vw, 1rem);
}
.font-20{
    font-size: clamp(1rem, 0.9500rem + 0.2500vw, 1.25rem);
}
.font-24{
    font-size: clamp(1rem, 0.7131rem + 0.6557vw, 1.5rem);
}
.font-26{
    font-size: clamp(0.625rem, 0.3773rem + 1.2384vw, 1.625rem);
}
.font-32{
    font-size: clamp(0.8rem, 0.4571rem + 1.5238vw, 2rem);
}
.font-40{
    font-size: clamp(1rem, 0.4783rem + 2.6087vw, 2.5rem);
}
.font-48{
    font-size: clamp(1.3rem, 0.8815rem + 2.0923vw, 3rem);
}
.font-54{
    font-size: clamp(2.375rem, 2.1273rem + 1.2384vw, 3.375rem);
}
.font-72{
    font-size: clamp(3.125rem, 2.7321rem + 1.7460vw, 4.5rem);
}
.font-85{
    font-size: clamp(4.25rem, 3.7353rem + 2.5736vw, 5.31rem);
}

.l-16{
   line-height: 16px;
}
.l-20{
    line-height: 20px;
}
.l-22{
    line-height: 22px;
}
.l-24{
    line-height: 24px;
}
.l-30{
    line-height: 30px;
}
.l-32{
    line-height: 32px;
}
.l-36{
    line-height: 36px;
}
.l-39{
    line-height: 39px;
}
.l-48{
    line-height: 48px;
}
.l-54{
    line-height: 54px;
}
.l-57-6{
    line-height:57.6px;
}
.l-85{
    line-height: 85px;
}
.l-86-4{
    line-height:86.4px;
}
/* Letter spacing starts*/
.ls-1{
    letter-spacing: -0.192px;
}
.ls-2{
    letter-spacing: -0.144px;
}
.ls-3{
    letter-spacing: 1px;
}
/* Letter spacing ends*/
.f-300{
    font-weight: 300;
}
.f-400{
    font-weight: 400;
}
.f-500{
    font-weight: 500;
}
.f-600{
    font-weight: 600;
}
.f-700{
    font-weight: 700;
}
.f-800{
    font-weight: 800;
}
.f-900{
    font-weight:900;
}
.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-uppercase{
    text-transform: uppercase;
}
.text-lowercase{
    text-transform: lowercase;
}
.text-capitalize{
    text-transform: capitalize;
}
.text-underline{
    text-decoration: underline;
}
.text-yellow2{
    color: var(--yellow2);
}
.w-fit{
    width: fit-content;
}
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-right{
    text-align: right;
}
/* font style */
