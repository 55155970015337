@media screen and (max-width: 1399.98px) {
    .referenceGrid{
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width: 991.98px) {
    .referenceGrid{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width: 567.98px) {
    .referenceGrid{
        grid-template-columns: repeat(1,1fr);
    }
}