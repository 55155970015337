.card-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}
.card-grid>div{
    min-width: 200px;
}
@media screen and (max-width: 1199.98px) {
    .card-grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 767.98px) {
    .card-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 567.98px) {
    .card-grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}