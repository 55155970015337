.nav-text {
  color: var(--blue);
  font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.aqua-heading {
  color: var(--aqua);
  font-size: clamp(4.25rem, 3.7353rem + 2.5736vw, 5.31rem);
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 85px */
  text-shadow: "2.25px 2.25px, 2.25px 2.25px, 2.25px 0px";
}
.blue-heading {
  color: var(--blue);
  /* font-family: Poppins; */
  font-size: clamp(2rem, 1.55rem + 2.25vw, 4.25rem);
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 86.4px */
  text-shadow: "2.25px 2.25px, 2.25px 2.25px, 2.25px 0px";
}
.modal-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5.5px);
  z-index: 1000;
}

input:checked {
  accent-color: var(--sea-green);
}

.scroll-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  display: flex;

  /* Make the cards flex items */
}
.scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* ScoreSlider */

.score-container {
  position: relative;
}
.score-dragger {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 26px;
  border-radius: 40px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.score-dragger::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 50px; /* Adjust the width of the thumb */
  height: 50px; /* Adjust the height of the thumb */
  background-color: #fff;
  border: 8px solid var(--sea-green);
  border-radius: 50%;
  cursor: pointer;
  margin-top: -7px; /* Adjust the vertical alignment */
}

.score-dragger:hover {
  opacity: 1;
}

.score-marker {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  user-select: none;
}
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); */
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    lightgray 50% / cover no-repeat, #bbccca;
  z-index: 1; /* Ensure the gradient appears on top of the image */
}

.settings-tab:hover {
  background-color: var(--sea-green);
  color: white;
  border-radius: 10px;
  /* padding: clamp(0.4687rem, 0.4124rem + 0.2813vw, 0.75rem); */
  cursor: pointer;
}

.text-container {
  display: -webkit-box; /* Old syntax for WebKit */
  display: -webkit-flex; /* For compatibility */
  display: flex; /* Standard */
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical; /* Vertical layout */
  overflow: hidden; /* Hide overflowing content */
}

.truncate-text {
  display: -webkit-box; /* Old syntax for WebKit */
  display: -webkit-flex; /* For compatibility */
  display: flex; /* Standard */
  -webkit-box-orient: vertical; /* Vertical layout */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display ellipsis (...) for truncated text */
}

.max-w-prose {
  max-width: 65ch; /* Adjust the value as needed */
  /* You may also want to add margin or padding styles if necessary */
}
.max-w-100 {
  max-width: 100%;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* Number of lines to show */
}
.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1; /* Number of lines to show */
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4; /* Number of lines to show */
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

/* .outer-div .mySwiper .swiper-wrapper {

  border : 5px green solid;
  min-height: 500px;

  
} */

/* Slider.css */

.mySwiper {
  width: 100%;
  height: 100%;
  padding: 20px; /* Optional: Add padding to create space around the carousel */
  overflow: visible; /* Ensure the next slide is visible */
}

.mySwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Ensure height adjusts based on content */
  /* height: 500px;  */
  background: #fff; /* Example background color */
  border-radius: 10px; /* Optional: Add rounded corners */
  /* box-sizing: border-box;
  border: 1px solid #ddd; 
  padding: 20px;  */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}
.min-h-500 {
  min-height: 500px;
}
.min-w-full {
  min-width: 100%;
}
.bg-image-play-btn {
  background-image: url("../../public/assets/PlayButton.svg");
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-image-not-found {
  background-image: url("../../public/assets/Image-Not-Found.png");
  /* width: 100px;
  height: 100px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.back-arrow-btn {
  background-image: url("../../public/assets/green-arrow-back-circle.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.truncate-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust the line height if necessary */
  max-height: calc(1.5em * 2); /* line-height * number of lines */
}
.scroll-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory; /* Optional: for snapping effect */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on touch devices */
}
