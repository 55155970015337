@media screen and (max-width: 1199.98px) {
    .wrapper{
        flex-direction: column;
        gap: 50px;
    }
    .btnWrapper{
        flex-direction: row;
    }
}
@media screen and (max-width: 567.98px) {
    .btnWrapper{
        flex-direction: column;
    }
}