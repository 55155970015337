.sourceGrid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}
@media screen and (max-width: 1199.98px) {
    .sourceGrid{
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
}
@media screen and (max-width: 567.98px) {
    .sourceGrid{
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
    }
}