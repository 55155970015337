.futrForWrapper {
    display :grid;
    grid-template-columns: 0.6fr 0.4fr;
    padding-block: 100px;
    
}
.futrForWrapper img {
    max-width: "100%";
}
.futrForWrapper > div {
    flex : 1
}

.footer{
    display: block;
}
.footerMobile{
    display: none;
}
.techCultureWrapper{
    display: flex;
}
.techCultureWrapper>div{
    width: 50%;
}
.signInbutton{
    width:280px
}
.quote-wrapper{
    z-index: 0;
}
.quote-wrapper>img{
    position: absolute;
    top: 10%;
    left: 5%;
    z-index: -1;
}
.showCase-img-container>img{
    width: 100%;
    object-fit: contain;
}
.quote{
    font-size: clamp(3.125rem, 2.7321rem + 1.746vw, 4.5rem);
}
@media (max-width:1024px) {
    .futrForWrapper {
        padding-block: 10px;
    }
    .logo{
        width: 160px;
        height: 60px;
    }
    .signInbutton{
        width: 160px;
    }
    .quote{
        font-size: clamp(2rem, 1.55rem + 2.25vw, 4.25rem);
    }
}
@media (max-width : 768px){
    .loginFormWrapper {
        
        margin: auto;
        text-align: center;

    }
    .trustedByWrapper{
        border-radius : 1rem;
    }

    .techCultureWrapper{
        flex-direction: column-reverse;
        text-align: center
    }
    .techCultureWrapper>div{
        width: 100%;
    }
    .techCultureContent{
        margin-inline: auto;
    }
    .futrForWrapper {
        display :flex;
        flex-direction: column;
        text-align: center
    }
    .spotlightWrapper{
        text-align: center;

    }
    .benefitsWrapper{
        text-align: center;
    }
    .footerLinks,
    .footerLinks>div{
        flex-direction: column;
        align-items: flex-start;
    }
    .footerMobile{
        display: block;
    }
    .logo{
        width: 160px;
        height: 60px;
    }
    .navigationBtn{
        gap: 28px;
    }
    .quote-wrapper>img{
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
}