.searchWrapper>span{
    display: none;
}
@media screen and (max-width: 767.98px) {
    .searchWrapper{
        flex-direction: column;
        gap: 8px;
    }
    .searchFields{
        flex-direction: column;
    }
    .searchFields>div:nth-child(1){
        border-right: none;
        width: 100%;

    }
    .searchFields>input{
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .searchWrapper>div:nth-child(2){
        display: none;
    }
    .searchWrapper>span{
        display: flex;
    }
}